.my-container {
  margin: 0 auto;
  width: 90%;
  text-align: center;
  max-width: 640px;
}

table {
  text-align: center;
}
